export default function APIUrl(location) {
    const local = 'http://localhost:5000';
    const staging = 'https://api.staging.survey.yourdaye.com';
    const prod = 'https://api.survey.yourdaye.com';
  
    const APIUrl = {
      localhost: local,
      'staging.survey.yourdaye.com': staging,
      'survey.yourdaye.com': prod,
    }[location];
  
    if (!APIUrl) {
      throw Error(
        `API url not configured for ${location}. See APIUrl in project root`
      );
    }
  
    return APIUrl;
  }
  