import React, { useContext, useState } from 'react'
import { TextInput } from './TextInput';
import Button from './Button'
import styled from 'styled-components';
import { D } from '@dayetopia/library';
import { Link } from 'gatsby'
import axios from 'axios'
import { navigate } from 'gatsby'
import Context from 'components/common/Context'
import setAuthToken from 'helpers/setAuthToken'
import getAPIUrl from '../../../getAPIUrl'

export default ({ }) => {
  const { user, dispatchUserAction } = useContext(Context)
  const [isSubmitting, setSubmitting] = useState(false)
  const [email, setEmail] = useState('')
  const [error, setError] = useState('')

  const handleChange = e => {
    setEmail(e.target.value)
  }

  const handleBlur = e => {
    if (!e.target.value) {
      setError('Required field')
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setSubmitting(true)
    const url = getAPIUrl(window.location.hostname)
    try {
      if (!email) {
        setError('Field is required')
      } else {
        setError('')
        const response = await axios.get(`${url}/user/verify`, {
          params: {
            email: email
          }
        })
        console.log('data', response.data)
        if (response.status === 201) {
        dispatchUserAction({ type: 'VERIFY_USER', payload: response.data })
        window.localStorage.setItem('userID', response.data.id)
        navigate('/private/survey/')}
        else {
          setError("Something went wrong")
        }
      }
    } catch (err) {
      console.log('error', err.response)
      err.response && setError(err.response.data.error.message)
    }
    setSubmitting(false)
  }

  return (
    <StyledForm onSubmit={handleSubmit}>
      <div className="input-field black-input">
        {/* <span className="email-icon" />
          <input
            onChange={handleChange}
            onBlur={handleBlur}
            type="email"
            placeholder="Enter your email"
            name="email"
          /> */}
        <TextInputStyled
          placeholder={'Enter your e-mail'}
          onChange={handleChange}
          onBlur={handleBlur}
          type="email"
          name="email"
        />
        {error &&
          <ErrorText>
            {error}
          </ErrorText>}
      </div>
      <div className="center-text">
        <ButtonStyled
          type="submit"
          disabled={isSubmitting}>
          confirm
            </ButtonStyled>
        {/* <button
            type="submit"
            disabled={isSubmitting}
            className="btn btn-rounded gradient-green"
          >
            submit
          </button> */}

      </div>
    </StyledForm>
  )
}

const ButtonStyled = styled(Button)`
  margin-bottom: 20px;
`;

const TextInputStyled = styled(TextInput)`
  margin-bottom: 20px;
`;

const ErrorText = styled(D.P)`
color: #ea534f;
font-size: 18px;
text-align: left;
`
const StyledForm = styled.form`
padding-right: 25%;
padding-left: 25%;
@media only screen and (max-width: 768px) {
padding-right: 5%;
padding-left: 5%;
}
`