import styled from 'styled-components';

const Button = styled.button`
  height: 60px;
  background-color: #003b1b;
  font-size: 16px;
  line-height: 1;
  font-family: 'BrownStd';
  font-weight: bold;
  letter-spacing: 1px;
  color: #fff;
  text-transform: uppercase;
  border: none;
  padding: 2px 30px 0 30px;
  flex-shrink: 0;
  transition: background-color 200ms ease-out;
  &:hover {
    cursor: pointer;
    background-color: rgba(0, 59, 27, 0.9);
  }
  @media only screen and (max-width: 768px) {
    height: 48px;
    padding: 2px 20px 0 20px;
  }

  ${(props: IButton) => {
        if (props.mobile) {
            return `
        height: 48px;
        font-size: 14px;
        padding: 2px 20px 0 20px;
      `;
        }
    }}

  ${(props: IButton) => {
        if (props.disabled) {
            return `
        opacity: 0.5;
        &:hover {
          cursor: default;
        }
      `;
        }
    }}
`;

export default Button;

export const ButtonSecondary = styled(Button)`
  color: #003b1b;
  background-color: transparent;
  border: 2px solid #003b1b;
  &:hover {
    background-color: transparent;
    border: 2px solid rgba(0, 59, 27, 0.8);
    color: rgba(0, 59, 27, 0.8);
  }
`;

export const ButtonDisabled = styled(Button)`
  opacity: 0.5;
  &:hover {
    cursor: default;
  }
`;

interface IButton {
    mobile?: boolean;
    disabled?: boolean;
}

export const QuestionnaireSubmitBtn = styled(Button)`
  margin-top: 20px;
  max-width: 200px;
  height: 50px;
  padding: 0;
  width: 150px;
  @media only screen and (max-width: 500px) {
    max-width: 300px;
    min-width: 300px;
  }
`;
