import styled from 'styled-components';

export const TextInput = styled.input`
  padding: 0 15px;
  font-size: 18px;
  font-family: BrownStd;
  background: #fefcea;
  border: 2px solid #00391e;
  outline: none;
  width: 250px;
  height: 50px;
  &::-webkit-input-placeholder {
    font-size: 14px;
  }
`;
